<template>
  <form @submit.prevent="updateTypeMaterial">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 v-text="headerData.title"></h2>
      <div class="d-flex gap-3">
        <UIButton
          type="button"
          v-show="!editable"
          text="Редактировать"
          leftIcon="bi-pencil"
          @click="goEdit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Сохранить"
          leftIcon="bi-save"
        />
        <UIButton
          type="button"
          v-show="editable"
          text="Удалить"
          leftIcon="bi-trash"
          @click="deleteTypeMaterial"
          float
        />
      </div>
    </div>
    <div class="row gap-3">
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.WorkDocument.Document.IdentifiedObject.name"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Тип затрат:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.costType"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Складская позиция:</dt>
            <dl class="col">
              <UISwitch v-model="data.stockItem" :editable="editable" />
            </dl>
          </div>
        </div>
      </dl>

      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Код типа материала:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.WorkDocument.Document.IdentifiedObject.enproCode"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Единица измерения:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.quantity"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Цена за единицу:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.estUnitCost.value"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Статус:</dt>
            <dl class="col">
              <EditableSelect
                :value="lodash.get(data, 'status.id')"
                @input="(e) => lodash.set(data, 'status.id', e)"
                :options="status"
                :editable="editable"
                required
              />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import UISwitch from "@/components/UI/Switch";

import {
  API_MultiplierList,
  API_StatusList,
  API_SymbolList,
} from "@/services/api";
import {
  API_type_material_delete,
  API_type_material_update,
  API_type_material_view,
} from "@/services/references/typeMaterial";

export default {
  name: "typeMaterialViewEdit",
  components: { UIButton, EditableInput, EditableSelect, UISwitch },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return true;
        default:
          return false;
      }
    },
    headerData: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return {
            title: "Редактирование типа",
          };
        default:
          return {
            title: "Просмотр типа",
          };
      }
    },
  },
  data() {
    return {
      /**
       * @type TypeMaterialObject
       */
      data: null,
      status: [],
      multipliers: [],
      units: [],
    };
  },
  mounted() {
    API_StatusList().then((res) => (this.status = res));
    API_SymbolList().then((res) => (this.units = res));
    API_MultiplierList().then((res) => (this.multipliers = res));
    API_type_material_view(parseInt(this.$route.params.typeId)).then(
      (res) => (this.data = res)
    );
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/references/type-material/${this.$route.params.typeId}/edit`,
      });
    },
    deleteTypeMaterial() {
      API_type_material_delete(parseInt(this.$route.params.typeId)).finally(
        () => {
          this.$router.push({
            path: "/references/type-material",
          });
        }
      );
    },
    updateTypeMaterial() {
      API_type_material_update(
        parseInt(this.$route.params.typeId),
        this.data
      ).then(() => {
        this.$router.push({
          path: `/references/type-material/${this.$route.params.typeId}/`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
