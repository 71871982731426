<template>
  <div class="form-check form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      id="flexSwitchCheckChecked"
      :checked="value"
      :disabled="!editable"
      :class="{ 'is-invalid': validate.$error }"
      @input="$emit('input', $event.target.checked)"
    />
    <label
      class="form-check-label"
      for="flexSwitchCheckChecked"
      v-text="label"
    ></label>
  </div>
</template>

<script>
export default {
  name: "Switch",
  props: {
    value: {
      type: Boolean,
      require: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: String,
    message: String,
    validate: {
      type: Object,
      default() {
        return {
          $error: false,
        };
      },
    },
  },
};
</script>

<style scoped></style>
